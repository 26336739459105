import { Link } from "gatsby"
import React from "react"
import styled, { keyframes } from "styled-components"
import Classes from "../styles/Classes"
import classnames from "classnames"

import robot from "../assets/images/mascotte.webp"

const Profil = () => {
    return (
        <div className="flex flex-col items-center bg-gray-100 rounded-lg w-56 h-72 opacity-20">

        </div>
    )
}

const Hero = () => (
    <div className="relative flex flex-row justify-around w-full max-w-3xl mx-auto">
        {/*<img
            src={robot}
            className="absolute top-0 right-0 w-64 mx-auto -mt-16 opacity-25 md:hidden"
        />*/}
        <div className="z-10 flex flex-col items-center w-full mt-16 space-y-8 md:w-auto md:items-start md:mt-0">
            <span className="max-w-sm text-3xl font-bold bg-gradient-to-r from-green-400 to-blue-500 text-transparent bg-clip-text animate-pulse">
               I do things on the Internet.
            </span>
            {/*<div className="w-72 h-0.5 bg-primary-200" />*/}
            <span className="max-w-sm text-sm font-light text-white">
                I like to build projects with amazing people from all around the world for fun 
                and money. I am currently studying engineering at EPFL in Switzerland IRL and building web 3 dApps in the Metaverse.
            </span>
            {/*<Link
                to="#invest"
                className={classnames(
                    Classes.textButton,
                    "self-center lg:self-start"
                )}
            >
                Join Now
                </Link>*/}
        </div>
        {/*<img src={robot} className="hidden w-64 mx-auto -mt-16 md:block" />*/}
        {/*<Profil/>*/}
    </div>
)

export default Hero
