import * as React from "react"
import ReactDOM from 'react-dom';

import PageLayout from "../components/PageLayout"
import SEO from "../components/SEO"
import Hero from "../components/Hero"
import Projects from "../components/Projects"
import Invest from "../components/Invest"
import OurProjects from "../components/OurProjects"
import FAQ from "../components/FAQ"
import Partners from "../components/Partners"
import Team from "../components/Team"
import Divider from "../components/Divider"
import Contact from "../components/Contact"
import Countdown from "../components/Countdown"
import Mm from "../components/wallet"
import App from "../components/App"
import {Buffer} from 'buffer';
import Myapp from "../components/_app"
//Buffer.from('anything','base64');

const IndexPage = () => (
    <PageLayout>

        <SEO title="Home" />
        <div className="w-0 h-16" />
        <Hero />
        <div className="w-0 h-32 md:h-32" />
        {/*<Invest />
        <div className="w-0 h-40 md:h-32" />
        <OurProjects />
        <div className="w-0 h-40 md:h-32" />
        <Partners />
        <div className="w-0 h-40 md:h-32" />
        <Contact />*/}
        <div className="w-0 h-48" />
        
    </PageLayout>
)

export default IndexPage
